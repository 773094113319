import React, { useEffect, useState } from "react";
import "./carrito.css";

import fotoUno from "../../img/blender.jpg";
import fotoDos from "../../img/code.png";
import fotoTres from "../../img/css.png";

import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

export default function Carrito() {
  const [galeria, setGaleria] = useState([]);
  const [valor, setValor] = useState({});
  const [carrito, setCarrito] = useState([]); // Estado para el carrito
  const [megusta, setMegusta] = useState([]); // Estado me gusta

  //-------------------FUNCION SUMAR-------------------
  const sumar = () => {
    let array = [fotoUno, fotoDos, fotoTres];
    setGaleria(array);

    // Inicializo las cantidades a 0 para cada artículo
    const cantidadesIniciales = {};
    array.forEach((_, index) => {
      cantidadesIniciales[index] = 0;
    });
    setValor(cantidadesIniciales);

    //Inicializo los megusta a 'false'
    const initialLikes = array.map(() => false); // Esto crea un array de falsos(no "megusta")
    setMegusta(initialLikes);
  };

  //-------------------FUNCION ACTUALIZAR CARRITO-------------------
  const updateCarrito = (index, quantity) => {
    setCarrito((prevCarrito) => {
      const existingItem = prevCarrito.find((item) => item.index === index);
      if (existingItem) {
        return prevCarrito.map((item) =>
          item.index === index ? { ...item, quantity } : item
        );
      }
      return [...prevCarrito, { index, quantity }];
    });
  };

  //-------------------FUNCION INCREMENTAL-------------------
  const increment = (index) => {
    setValor((prevCantidades) => {
      const newQuantity = prevCantidades[index] + 1;
      updateCarrito(index, newQuantity); // Actualizar el carrito
      return {
        ...prevCantidades,
        [index]: newQuantity,
      };
    });
  };

  //-------------------FUNCION RESTA-------------------
  const decrement = (index) => {
    setValor((prevCantidades) => {
      const newQuantity = Math.max(prevCantidades[index] - 1, 0);
      updateCarrito(index, newQuantity); // Actualizar el carrito
      return {
        ...prevCantidades,
        [index]: newQuantity,
      };
    });
  };

  //-------------------FUNCION SETEO-------------------
  const setear = (index) => {
    setValor((prevCantidades) => {
      updateCarrito(index, 0); // Actualizar el carrito al eliminar
      return {
        ...prevCantidades,
        [index]: 0,
      };
    });
  };

  const getTotalItems = () => {
    return carrito.reduce((total, item) => total + item.quantity, 0);
  };

  //-------------------FUNCION ME GUSTA-------------------
  const mg = (index) => {
    setMegusta((prevMegusta) => {
      const newMegusta = [...prevMegusta]; // Copia el estado actual
      newMegusta[index] = !newMegusta[index]; // Cambiar el valor del corazon especifico
      return newMegusta;
    });
  };

  //-------------------FUNCION ACTUALIZAR CORAZON-------------------
  const getTotalLikes = () => {
    return megusta.filter((like) => like === true).length;
  };

  useEffect(() => {
    sumar();
  }, []);

  return (
    <div className="carrito">
      <div className="info">
        <h3>E-Comerce</h3>
        <span
          className={`cartIcon heart ${
            getTotalLikes() > 0 ? "heartActivo" : ""
          }`}
        >
          <FontAwesomeIcon icon={faHeart} />
        </span>
        <span className="cartIcon">
          <span className="totalItems">{getTotalItems()}</span>
          <FontAwesomeIcon icon={faCartShopping} />
        </span>
      </div>

      <input type="text" className="search" />
      <ul className="segmentos">
        <li>Explorar</li>
        <li>Electronica</li>
        <li>Ropa</li>
        <li>Mas Buscado</li>
      </ul>
      <div className="lugar">
        {galeria.map((foto, index) => (
          <div
            className="fotoCont"
            key={index}
            onContextMenu={(e) => e.preventDefault()}
          >
            <span className="mg" onClick={() => mg(index)}>
              <FontAwesomeIcon
                icon={faHeart}
                className={`icons ${
                  megusta[index] ? "corazonActivo" : "corazonInactivo"
                }`}
              />
            </span>
            <img
              src={foto}
              alt={`foto ${index}`}
              onContextMenu={(e) => e.preventDefault()}
            />
            {valor[index] === 0 && (
              <div className="botonera">
                <button className="sumar" onClick={() => increment(index)}>
                  Agregar +
                </button>
              </div>
            )}

            {valor[index] > 0 && (
              <div className="boton">
                <button className="sumarMitad" onClick={() => increment(index)}>
                  Agregar +
                </button>
                <button
                  className="eliminarMitad"
                  onClick={() => decrement(index)}
                >
                  Eliminar -
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import "./headerSmall.css";
import { rutaAPI } from "../../config";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { useLenguaje } from "../../hook/UseLenguaje";

export default function HeaderSmall() {
  //FUNCION PARA COPAR EL TEXTO
  const copy = () => {
    navigator.clipboard
      .writeText(email)
      .then(() => {
        console.log("Texto copiado al portapapeles");
      })
      .catch((Err) => {
        console.log("Erro al copiar el texto", Err);
      });
  };

  //==============HOOK========================
  const { lenguaje, toogleLenguaje } = useLenguaje();
  const { t } = useLenguaje();

  //1 - Configurar los hooks
  const [galeria, setGaleria] = useState([]);
  const [imagenPrincipal, setImagenPrincipal] = useState(null);

  //2 - Funcion para mostrar los datos con tetch
  const verPerfil = async () => {
    try {
      const response = await getData();
      const data = await response.json();

      if (!data || !Array.isArray(data.docs)) {
        throw new Error(
          "La respuesta de la api no contiene los datos esperados"
        );
      }
      // Asignar la primera imagen de la galería (o cualquiera que desees).
      if(data.docs.length> 0){
        setGaleria(data.docs);
        setImagenPrincipal(`${rutaAPI}/api/verPerfil/${data.docs[0].id}`);
      }
      else{setImagenPrincipal(null)}
    } catch (error) {
      console.error("error la obtener los datos", error);
    }
  };
  useEffect(() => {
    verPerfil();
  }, []);

  //===========CONSTANTES=====================
  const email = "ngunaris@gmail.com";

  return (
    <div className="headerSmall">
      <div className="presentationSmall">
        <div className="contentImgSmall">
          <a href="/"><img src={imagenPrincipal} alt="mypicture"></img></a>
        </div>
        <div className="datosSmall">
          {email}
          <i onClick={copy} className="iconSmall">
            <FontAwesomeIcon icon={faCopy} />
          </i>
        </div>
      </div>
    </div>
  );
}

/*PETICION GET PARA GALERIA*/
const getData = async () => {
  try {
    const url = `${rutaAPI}/api/mostrarPerfil`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error("Error al obtener los datos");
    }
    return response;
  } catch (err) {
    return err;
  }
};

import React, { useEffect, useState } from "react";

import "./calculadora.css";

export default function Calculadora() {
  const [input, setInput] = useState("");
  const [resultado, setResultado] = useState(null);
  const [resultadoOculto, setResultadoOculto] = useState("");
  const [operacion, setOperacion] = useState(null);

  const asignar = (valor) => {
    setInput((prev) => prev + valor);
  };

  const calcularResultado = () => {
    try {
      const result = eval(input);
      setInput(result);
    } catch (error) {
      setResultado("Error");
    }
  };

  const limpiar = () => {
    setInput("");
    setResultado(null);
    setResultadoOculto("");
    setOperacion(null);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      const key = event.key;

      if (!isNaN(key) || key === "." || key === "+") {
        asignar(key);
      } else if (key === "-") {
        asignar("-");
      } else if (key === "*") {
        asignar("*");
      } else if (key === "/") {
        asignar("/");
      } else if (key === "Enter") {
        calcularResultado();
      } else if (key === "Backspace") {
        setInput((prev) => prev.slice(0, -1)); // Remove last character
      } else if (key === "Escape") {
        limpiar();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [input]);

  const numeros = [7, 8, 9, 4, 5, 6, 1, 2, 3, 0, "."];
  const productos = ["+", "-", "*", "/", "%"];

  return (
    <div className="calculadora">
      <div className="visor">
        <div>{input}</div>
        <div>{resultado}</div>
      </div>

      <div className="numeros">
        <button onClick={limpiar} className="botones">
          C
        </button>
        <button onClick={() => asignar("(")} className="botones">
          ()
        </button>
        <button onClick={() => asignar("%")} className="botones">
          %
        </button>
        <button onClick={() => asignar("/")} className="botones">
          /
        </button>
        <button onClick={() => asignar("7")} className="botones">
          7
        </button>
        <button onClick={() => asignar("8")} className="botones">
          8
        </button>
        <button onClick={() => asignar("9")} className="botones">
          9
        </button>
        <button onClick={() => asignar("*")} className="botones">
          x
        </button>
        <button onClick={() => asignar("4")} className="botones">
          4
        </button>
        <button onClick={() => asignar("5")} className="botones">
          5
        </button>
        <button onClick={() => asignar("6")} className="botones">
          6
        </button>
        <button onClick={() => asignar("-")} className="botones">
          -
        </button>
        <button onClick={() => asignar("1")} className="botones">
          1
        </button>
        <button onClick={() => asignar("2")} className="botones">
          2
        </button>
        <button onClick={() => asignar("3")} className="botones">
          3
        </button>
        <button onClick={() => asignar("-")} className="botones">
          -/+
        </button>
        <button onClick={() => asignar("+")} className="botones">
          +
        </button>
        <button onClick={() => asignar("0")} className="botones">
          0
        </button>
        <button onClick={() => asignar(".")} className="botones">
          .
        </button>
        <button onClick={calcularResultado} className="botones">
          =
        </button>
      </div>
    </div>
  );
}

import React, { useState, useContext, useEffect } from "react";
import "./admin.css";
import FotoPerfil from "../componentesAdmin/fotoPerfil/FotoPerfil";
import FotosRenders from "../componentesAdmin/fotosRenders/FotosRenders";
import FotoDiseño from "../componentesAdmin/fotosDiseño/FotoDiseño";
import { AdminContext } from "../../context/adminContext";

export default function Admin() {
  /*==========ESTADO DE USUARIO==========*/
  const { adminLogueado, setAdminLogueado } = useContext(AdminContext);

  const CerrarSesion = () => {
    localStorage.removeItem("ACCESS_TOKEN");
    localStorage.removeItem("EXPIRATION_TIME");
    localStorage.removeItem("ADMINISTRADOR");
    setAdminLogueado(null); //Limpio el estado en el contexto
    window.location.href = "/admin"; //Redirigo a la pagina de inicio o a la pagina de login
  };

  const UpperCase = ()=>{
    if(adminLogueado){
      const primerLetraMayuscula = adminLogueado.charAt(0).toUpperCase() + adminLogueado.slice(1).toLowerCase();
      setAdminLogueado(primerLetraMayuscula);
    }
  }

  useEffect(()=>{
    UpperCase()
  },[adminLogueado])

  return (
    <div className="admin">
      {adminLogueado ? (
        <div className="sesion">
          <h2>{adminLogueado}</h2>
          <button onClick={CerrarSesion}>Cerrar Sesion</button>
        </div>
      ) : null}

      <FotoPerfil />
      <FotosRenders />
      <FotoDiseño />
    </div>
  );
}

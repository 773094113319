import React from "react";
import "./biografia.css";
import { useLenguaje } from "../../hook/UseLenguaje";

export default function Biografia() {
  const { t } = useLenguaje();

  return (
    <div className="biography">
      <h2>{t("item9")}</h2>
      {t("item10").map((espacio, index)=>(
        <p key={index}>{espacio}</p>
      ))}

    </div>
  );
}

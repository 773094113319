import React, { useState, useEffect } from "react";
import { rutaAPI } from "../../../config";
import "./fotosRenders.css";

export default function FotosRenders() {
  const [foto, setFoto] = useState([]);
  const [image, setImage] = useState(null); // Imagen nueva
  const [imagenPreview, setImagenPreview] = useState(null); // Vista previa de imagen nueva

  const [imagePreviewEdit, setImagePreviewEdit] = useState({}); // Vista previa de edición
  const [selectedProduct, setSelectedProduct] = useState(null); // Producto seleccionado para edición

  // Obtener productos desde la API
  const url = `${rutaAPI}/api/mostrarPic`;
  const token = localStorage.getItem("ACCESS_TOKEN");
  const showData = async () => {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    console.log(data.docs);
    setFoto(data.docs);
  };

  // Función para manejar la selección de imagen
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagenPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setImage(null);
      setImagenPreview(null);
    }
  };

  // Agregar nuevo producto a la API
  const addProduct = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("imagen", image);

    try {
      const response = await fetch(`${rutaAPI}/api/agregarPic`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`, // Incluye el token
        },
      });
      if (!response.ok) {
        throw new Error("Error al agregar producto");
      }
      showData();
      setImage(null);
      setImagenPreview(null);
    } catch (error) {
      console.error("Error al agregar producto:", error);
    }
  };

  // Eliminar producto de la API
  const deleteProduct = async (id) => {
    try {
      await fetch(`${rutaAPI}/api/borrarPic/${id}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`, // Incluye el token
        },
      });
      showData();
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  // Función para manejar la selección de imagen para edición
  const handleImageChangeEdit = (e, productId) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviewEdit((prevState) => ({
          ...prevState,
          [productId]: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    } else {
      setImagePreviewEdit((prevState) => ({
        ...prevState,
        [productId]: null,
      }));
    }
  };

  // Función para actualizar la imagen en la API
  const updateProduct = async (e) => {
    e.preventDefault();
    if (!selectedProduct || !image) {
      console.error("Debe seleccionar una imagen para actualizar.");
      return;
    }

    const formData = new FormData();
    formData.append("imagen", image);

    try {
      const response = await fetch(
        `${rutaAPI}/api/editarPic/${selectedProduct.id}`,
        {
          method: "PUT",
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`, // Incluye el token
          },
        }
      );
      if (!response.ok) {
        throw new Error("Error al actualizar la imagen.");
      }
      showData();
      setImage(null); // Limpiar imagen y vista previa
      setImagePreviewEdit({});
      setSelectedProduct(null); // Limpiar producto seleccionado
    } catch (error) {
      console.error("Error al actualizar la imagen:", error);
    }
  };

  // Función para manejar la selección de producto
  const handleProductSelect = (product) => {
    setSelectedProduct(product);
    setImagePreviewEdit((prev) => ({
      ...prev,
      [product.id]: null,
    })); // Limpiar vista previa de la imagen de edición al seleccionar un nuevo producto
  };

  useEffect(() => {
    showData();
  }, []);

  return (
    <div className="imgRender">
      <div className="boxFormRender">
        <form className="formRender" onSubmit={addProduct}>
          <h2>Agregar Img a Render</h2>
          <input
            type="file"
            className="formInput"
            onChange={handleImageChange}
          />
          <button className="btnAgregarRender" type="submit">
            Agregar Producto
          </button>
          {imagenPreview && (
            <div className="boxMuestraRender">
              <img
                className="muestraRender"
                src={imagenPreview}
                alt="Vista Previa"
              />
            </div>
          )}
        </form>

        {foto.length > 0 && (
          <form className="formPerfilRender" onSubmit={updateProduct}>
            <h2>Editar Img de Render</h2>
            <ul className="listaRenderEditar">
              {foto.map((productos) => (
                <li key={productos.id}>
                  <label
                    htmlFor={`file-upload-${productos.id}`}
                    className="custom-file-upload"
                  >
                    Select
                  </label>
                  <input
                    type="file"
                    id={`file-upload-${productos.id}`}
                    className="formInputRender"
                    onChange={(e) => handleImageChangeEdit(e, productos.id)}
                    onClick={() => handleProductSelect(productos)}
                  />
                  {productos.ruta && !imagePreviewEdit[productos.id] && (
                    <div className="boxMuestraRender">
                      {console.log(
                        "Ruta completa de la imagen:",
                        `${rutaAPI}/${productos.ruta}`
                      )}
                      <img
                        className="muestraeRender"
                        src={`${rutaAPI}/${productos.ruta}`}
                        alt={productos.nombre}
                      />
                    </div>
                  )}
                  {imagePreviewEdit[productos.id] && (
                    <div className="boxMuestraRender">
                      <img
                        className="muestraRender"
                        src={imagePreviewEdit[productos.id]}
                        alt="Vista Previa"
                      />
                    </div>
                  )}
                  <div className="botonera">
                    <button className="btnAgregarRender" type="submit">
                      Actualizar
                    </button>
                    <button
                      className="btnEliminarRender"
                      type="button"
                      onClick={() => deleteProduct(productos.id)}
                    >
                      Eliminar
                    </button>
                  </div>
                </li>
              ))}
            </ul>
          </form>
        )}
      </div>
    </div>
  );
}

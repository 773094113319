import React, { useContext, useState } from "react";
import "./login.css";
import { rutaAPI } from "../../../config";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { AdminContext } from "../../../context/adminContext";

export default function Login() {
  /*=====================
  HOOK PARA INICIAR SECION
  =======================*/
  const [admin, setadmin] = useState({
    nombre: "",
    password: "",
  });

  /*=====================
  VARIABLE DE ERRORES
  =======================*/
  const [errorMessage, setErrorMessage] = useState(null);
  const [okMessage, setOkMessage] = useState(null);

  /*=====================
  HOOK PARA GUARDAD USUARIO LOGuEADO
  =======================*/
  const { setAdminLogueado, adminLogueado } = useContext(AdminContext);

  /*==========================
     CAPTURAMOS CAMBIOS DEL FORMULARIO PARA EJECUTAR LA FUNCION DEL HOOK
     ==============================*/
  const cambiarForm = (e) => {
    const { name, value } = e.target;
    setadmin((prevstate) => ({
      ...prevstate,
      [name]: value,
    }));
  };

  /*==========================
     EJECUTAMOS EL SUBMIT
      ==============================*/

  const login = async (e) => {
    e.preventDefault();
    

    if (!admin.nombre || !admin.password) {
      setErrorMessage("Ingresa el nombre de usuario y contraseña");
      return;
    }

    const result = await loginAPI(admin);
    console.log("Resultado del login", result);

    if (result && result.status === 200) {
      setOkMessage("Usuario ingresado correctamente");
      if (result.token) {
        localStorage.setItem("ACCESS_TOKEN", result.token);
        const expirationTime = Date.now() + 3600000;
        localStorage.setItem("EXPIRATION_TIME", expirationTime);
      }
      if (result.nombre) {
        localStorage.setItem("ADMINISTRADOR", result.nombre);

        //Actualizas el estado de React con el usuario logueado
        setAdminLogueado(result.nombre);
      }

      window.location.href = "/admin";
    } else {
      setErrorMessage("Erro de inicio de sesion");
    }
  };

  const signUp = () => {
    window.location.href = "/admin";
  };

  return (
    <div className="boxLogin">
      <div className="login" style={{ width: "20rem" }}>
        <div className="user">
          <FontAwesomeIcon icon={faUser} />
        </div>
        <div className="login-form">
          <form onChange={cambiarForm} onSubmit={login}>
            <div className="input-group">
              <div className="input-group-append">
                <FontAwesomeIcon icon={faUser} />
              </div>
              <input
                className="form-control"
                type="text"
                id="name"
                placeholder="Administrador"
                name="nombre"
                value={admin.nombre}
                onChange={cambiarForm}
              ></input>
            </div>
            <div className="input-group">
              <div className="input-group-append">
                <FontAwesomeIcon icon={faKey} />
              </div>
              <input
                className="form-control"
                id="pass"
                type="password"
                placeholder="Password"
                name="password"
                value={admin.password}
                onChange={cambiarForm}
              ></input>
            </div>
            {errorMessage ? (
              <div className="alert alert-danger">{errorMessage}</div>
            ) : null}
            {okMessage ? (
              <div className="alert alert-success">{okMessage}</div>
            ) : null}
            <div id="botonBox">
              <button type="submit" className="botonLogin" id="login">
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

/*PETICION POST PARA EL LOGIN*/
const loginAPI = async (data) => {
  const url = `${rutaAPI}/api/logearAdmin`;
  const params = {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const response = await fetch(url, params);
    if (!response.ok) throw new Error("Login failed");
    const result = await response.json();
    console.log("Resultado de la API:", result); // Agrega este log para verificar la respuesta
    return result;
  } catch (err) {
    console.error(err);
    return null;
  }
};

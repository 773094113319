import React, { useEffect, useState } from "react";
import "./home.css";

import Header from "../components/header/Header";
import Biografia from "../components/biografia/Biografia";
import Proyectos from "../components/proyectos/Proyectos";
import CursorLight from "../cursosLight/CursorLight";
import Footer from "../components/footer/Footer";
import Habilidades from "../components/habilidades/Habilidades";
import { useLenguaje } from "../hook/UseLenguaje";

export default function Home() {
  return (
    <div className="home">
      <Header />
      <Habilidades />
      <Biografia />
      <Proyectos />
      <Footer />
    </div>
  );
}

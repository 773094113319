import React, { useEffect, useState } from "react";
import HeaderSmall from "../components/headerSmall/HeaderSmall";
import Calculadora from "../components/calculadora/Cacluladora";

import "./codigo.css";
import Carrito from "../components/carrito/Carrito";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";

export default function Codigo() {
  const [selectedContent, setSelectedContent] = useState(null);

  //Array de datos para los cards
  const cardsData = [
    {
      id: 1,
      title: "Calculadora",
      icon: faCalculator,
      component: <Calculadora />,
    },
    {
      id: 2,
      title: "Carrito",
      icon: faCartShopping,
      component: <Carrito />,
    },
  ];

  const agrandar = (content) => {
    setSelectedContent(content);
  };

  const cerrarModal = () => {
    setSelectedContent(null);
  };

  return (
    <div className="renders">
      <HeaderSmall />
      <div className="encabezado">
        <a className="seleccion" href="/diseño">
          <h3>Mis Diseños</h3>
        </a>
        <a>
          <h2>Mis Codigos</h2>
        </a>
        <a className="seleccion" href="/renders">
          <h3>Mis Renders</h3>
        </a>
      </div>

      {cardsData.map((card) => (
        <div className="card" key={card.id}>
          <div className="left-column">
            <a onClick={() => agrandar(card.component)}>
              <i className="icon">
                <FontAwesomeIcon icon={card.icon} />
              </i>
            </a>
          </div>
          <div className="right-column">
            <h4>{card.title}</h4>
            <p></p>
          </div>
        </div>
      ))}

      {selectedContent && (
        <div className="modal">
          <div className="cerrarModal" onClick={cerrarModal}>
            X
          </div>
          <div className="modalContent" onClick={(e) => e.stopPropagation()}>
            {selectedContent}
          </div>
        </div>
      )}
    </div>
  );
}
